import api from '@/base/utils/request';


// 获取配置
export const campusAndPeriod = () => {
    return api({
        url: '/admin/school/settings/campusAndPeriod',
        method: 'post'
    })
}

// 保存校区
export const saveCampus = (data) => {
    return api({
        url: '/admin/school/settings/saveCampus',
        method: 'post',
        data
    })
}

// 保存学段
export const savePeriod = (data) => {
    return api({
        url: '/admin/school/settings/savePeriod',
        method: 'post',
        data
    })
}


// 删除校区
export const deleteCampus = (data) => {
    return api({
        url: '/admin/school/settings/deleteCampus',
        method: 'post',
        data
    })
}

// 删除学段
export const deletePeriod = (data) => {
    return api({
        url: '/admin/school/settings/deletePeriod',
        method: 'post',
        data
    })
}